var burgerMenu = document.querySelector("#main-nav .burger-menu");
if (burgerMenu) {
    burgerMenu.addEventListener("click", function () {
        document.querySelector(".main-menu").classList.toggle("open");
    });

    document.querySelector(".main-menu .close").addEventListener("click", function () {
        document.querySelector(".main-menu").classList.remove("open");
    });
}

var tutoSlider = null;
if (document.querySelector(".tuto-slider")) {
    tutoSlider = tns({
        container: ".tuto-slider",
        controls: false,
        navPosition: "bottom",
        loop: false,
        gutter: 20
    });

    var tutoSliderButton = document.querySelector(".tuto-slider-next");
    var container = tutoSlider.getInfo().container;
    var startBtn = container.getAttribute("data-start-btn") || "Suivant";
    tutoSliderButton.innerHTML = startBtn;
}

if (tutoSlider) {
    tutoSlider.events.on("transitionStart", function (info) {
        var page = document.querySelector(".page");
        page.className = page.className.replace(/slide-\d/, "slide-" + info.index);

        var tutoSliderButton = document.querySelector(".tuto-slider-next");
        var container = tutoSlider.getInfo().container;
        var startBtn = container.getAttribute("data-start-btn") || "Suivant";
        var endBtn = container.getAttribute("data-end-btn") || "Terminer";

        if (!info.index) tutoSliderButton.innerHTML = startBtn;
        else if (info.index == info.slideCount - 1) tutoSliderButton.innerHTML = endBtn;
        else tutoSliderButton.innerHTML = "Suivant";

        setTimeout(function () {
            if (info.index == info.slideCount - 1) tutoSliderButton.classList.add("is-last");
            else tutoSliderButton.classList.remove("is-last");
        }, 0);
    });
}

var tutoSliderButton = document.querySelector(".tuto-slider-next");
if (tutoSliderButton) {
    tutoSliderButton.addEventListener("click", function () {
        tutoSlider.goTo("next");
        if (tutoSliderButton.classList.contains("is-last") && tutoSliderButton.getAttribute("data-link")) location.href = tutoSliderButton.getAttribute("data-link");
    });
}

var toggles = document.querySelectorAll(".toggles .toggle");
toggles.forEach(function (toggle) {
    toggle.addEventListener("click", function (e) {
        var target = e.target;

        if (!target.classList.contains("active")) {
            target.parentElement.nextElementSibling.classList.toggle("disabled");
        }

        for (var key in target.parentElement.children) {
            target.parentElement.children[key].classList.remove("active");
        }
        target.classList.add("active");
    });
});

if (window.Html5Qrcode) {
    const html5QrCode = new Html5Qrcode("qr-reader");
    html5QrCode
        .start(
            { facingMode: "environment" },
            {
                fps: 10, // Optional, frame per seconds for qr code scanning
                qrbox: { width: 250, height: 250 } // Optional, if you want bounded box UI
            },
            decodedText => {
                document.body.classList.add("loading");
                location.replace(decodedText);
            }
        )
        .then(() => {
            document.querySelector(".qr-alert").style.display = "none";
        })
        .catch(err => {
            if (err.indexOf("NotAllowedError") != -1) document.querySelector(".qr-alert").style.display = "block";
        });
}
